import React from "react"
import { Card } from "react-bootstrap"
import { Link } from "gatsby"

const Index = ({ title, image, date, key, slug }) => {
  let sortTitle = title
  // const length = 25
  // if (title.length > length) {
  //   sortTitle = title.substring(0, length) + " ..."
  // }
  return (
    <>
      <Link key={key} className="individual-news" href={slug}>
        <Card style={{ margin: "10px auto", height: 380, width: 280 }}>
          <Card.Img
            variant="top"
            style={{
              objectFit: "cover",
              display: "block",
            }}
            height={250}
            src={`${
              image ? image : "../../../../images/Berita_default-image.png"
            }`}
          />
          <Card.Body>
            <div className="d-flex flex-column justify-content-between h-100">
              <div className="d-flex flex-column h-100">
                <span style={{ fontSize: 20, fontWeight: "bold" }}>
                  {sortTitle}
                </span>
              </div>
              <span style={{ opacity: ".5", fontSize: 14 }}>{date}</span>
            </div>
          </Card.Body>
        </Card>
      </Link>
    </>
  )
}

export default Index
